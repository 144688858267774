import React from 'react';
import { Box, Text, Flex } from 'rebass/styled-components'
import styled from 'styled-components'

const StatusDot = styled(Box) `
    height:14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
`

function Quote({ data }) {
    let statusColor = 'orange'
    if (data.status === 'approved') {
        statusColor = 'green'
    } else if (data.status === 'rejected') {
        statusColor = 'red'
    }

    return (
        <Flex flexDirection='column' my='sm' pb='sm' mx='md' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
            <Flex justifyContent='space-between'>
                <Text variant='body' as='p'>
                    {data.title}
                </Text>
                {/* In case we ever figure out what that second total is for... */}
                {/* <Text variant='body' as='p'>
                    {data.itemCost}
                </Text> */}
            </Flex>
            <Flex justifyContent='space-between'>
                <Text variant='body' fontWeight='semibold' as='p' my=''>
                    Total cost:
                </Text>
                <Text variant='body' fontWeight='semibold' as='p'>
                    {data.total}
                </Text>
            </Flex>
            <Flex justifyContent='space-between'>

                <Text variant='body' as='p'>
                    Status:
                </Text>
                <Flex>
                    <Text mr='xxs' variant='body' as='p' sx={{ textTransform: 'capitalize' }}>
                        {data.status}
                    </Text>
                    <StatusDot bg={statusColor} />
                </Flex>
            </Flex>
        </Flex>
    );
}

export default Quote;
