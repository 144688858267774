import React, { useEffect, useState } from 'react';
import { onSnapshot, collection, query, where, limit } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { connect } from 'react-redux'
import { Box, Text, Flex } from 'rebass/styled-components'
import { Link } from 'gatsby'
import Quote from './subcomponents/Quote'
import Icon from '../../assets/icons/quotes.svg'


const QuotesCard = ({ vehicle }) => {
    const [quotes, setQuotes] = useState([])
    useEffect(() => {
        const q = query(collection(db, 'quotes'), where('vid', '==', vehicle.vid), limit(2));
        onSnapshot( q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                output.push(doc.data())
            })
            setQuotes(output)
        })
    }, [vehicle])
    return (
        <Box>
            <Flex alignItems="center" sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Box ml='md' width='25px'>
                    <Icon />
                </Box>
                <Text mx='sm' py='xs' variant='h4' as='h4'>
                    LATEST QUOTES
                </Text>
                <Box ml='auto' mr='md'>
                    <Link to={'/clients/quotes/' + vehicle.vid} >
                        <Text variant='body' color='aquaMedium'>
                            View all
                        </Text>
                    </Link>
                </Box>
            </Flex>
            <Box mt='md'>
                {
                    quotes.length ?
                        quotes.map((quote) => {
                            return <Box key={quote.id}>
                                <Quote data={quote}/>
                            </Box>
                        }) :
                        <Text ml='md' as='p' variant='copy'>No quotes to display</Text>
                }
            </Box>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
    };
}

export default connect(mapStateToProps)(QuotesCard);
