import React, { useEffect } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import Layout from '../../../templates/Main'
import Dashboard from '../../../modules/Dashboard'
import Timeline from '../../../modules/Timeline'
import Swiper from '../../../modules/Swiper'
import Heading from '../../../modules/Heading'
import Modal from '../../../modules/Modal'
import HeaderLines from '../../../components/nav/HeaderLines'
import Brandmark from '../../../assets/icons/brandmark-transparent.svg'
import { connect } from 'react-redux'
import { setVehicle } from '../../../models/Vehicles'

const DashboardPage = ({ params, dispatch, vehicle }) => {
    useEffect(() => {
        setVehicle(dispatch, params.id)
    }, [])
    return (
        <Layout id={params.id}>
            <Heading size='h1' text={vehicle.title} />
            <Dashboard />
            <HeaderLines />
            <Timeline />
            <Box my='md'>
                <Swiper type='gallery' />
            </Box>
            <Box
                variant='container'
                my='xl'
                sx={{ position: 'relative', zIndex: 0 }}
            >
                <Box
                    width='100%'
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: [
                            '1fr',
                            '1fr',
                            '1fr',
                            '1fr',
                            '1fr 1fr 1fr',
                        ],
                        position: 'absolute',
                        bottom: '0px',
                    }}
                >
                    <Flex>
                        <Box width='100%'>
                            <Brandmark />
                        </Box>
                    </Flex>
                    <Box
                        height='200%'
                        display={['none', 'none', 'none', 'none', 'block']}
                        sx={{
                            borderLeft: '1px solid rgba(255,255,255,0.1)',
                            borderRight: '1px solid rgba(255,255,255,0.1)',
                            position: 'relative',
                            top: '-100%',
                        }}
                    />
                </Box>
            </Box>
            <Modal />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
    }
}

export default connect(mapStateToProps)(DashboardPage)
