import React from 'react';
import { Box, Text, Flex } from 'rebass/styled-components'
import ProgressBar from '../../general/ProgressBar'
import Moment from 'react-moment'


function Invoice({ data }) {
    let statusColor = 'orange'
    if (data.percentage === 100) {
        statusColor = 'green'
    } else if (data.status === 'overdue') {
        statusColor = 'red'
    }
    return (
        <>
            <Flex justifyContent='space-between' mt='md' >
                <Box mx= 'md' flexBasis='50%'>
                    <Text variant='largeCopy'>
                        Invoice {data.id}
                    </Text>
                    <Text variant='copy' as='p' my='xxs'>
                        Issued: <Moment unix format='DD/MM/YY'>
                            {data.issue_date}
                        </Moment>
                    </Text>
                </Box>
                <Box flexBasis='50%' mx= 'md'>
                    <Text variant='copy' as='p' sx={{ textTransform: 'capitalize' }}>
                        Status: {data.status}
                    </Text>
                    <Text variant='largeCopy' my='xxs'> {data.percentage}%</Text>
                </Box>
            </Flex>
            <Box ml='md' mr={['md', 'md', 'md', 'xxl']} my='xs'>
                <ProgressBar color={statusColor} percent={data.percentage} />
            </Box>
        </>
    );
}

export default Invoice;
