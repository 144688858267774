import React, { useState, useEffect } from 'react'
import { onSnapshot, collection, query, where } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { connect } from 'react-redux';
import { Box, Text, Flex } from 'rebass/styled-components'
import Icon from '../../assets/icons/timeline.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import ArrowRight from '../../assets/icons/arrow-right.svg'


const TimelineCard = ({ vid }) => {
    const [timeline, setTimeline] = useState([])
    const [lastCompleted, setLastCompleted] = useState()
    const [upcoming, setUpcoming] = useState()

    useEffect(() => {
        if (vid != undefined) {
            const q = query(collection(db, 'timelines'), where('vid', '==', vid));
            onSnapshot( q, (snapshot) => {
                let output = null
                snapshot.docs.map((doc) => {
                    output = doc.data()
                })
                setTimeline(output)
            })
        }
    }, [vid])
    useEffect(() => {
        if (timeline && timeline.nodes) {
            let lastItem = timeline.nodes[timeline.nodes.length - 1]
            if (lastItem !== undefined && lastItem.upcoming) {
                lastItem = timeline.nodes[timeline.nodes.length - 2]
            }
            const upcomingItemArray = timeline.nodes.filter((node) => node.upcoming)
            const lastUpcomingItem = upcomingItemArray[upcomingItemArray.length - 1]
            setLastCompleted(lastItem)
            setUpcoming(lastUpcomingItem)
        }
    }, [timeline])
    return (
        <Box>
            <Flex alignItems='center' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Box ml='md' width='30px'>
                    <Icon />
                </Box>
                <Text as='h4' variant='h4' mx='sm' py='xs'>
                    TIMELINE STATUS
                </Text>
            </Flex>
            <Box my='sm' mx= 'md'>
                <Flex alignItems='center' width='100%' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                    <Box width='23px' mr='sm'>
                        <ArrowLeft />
                    </Box>
                    <Text variant='largeCopy' as='p' my='sm'>
                        Last Completed
                    </Text>
                </Flex>
                {
                    lastCompleted ?
                        <Text as='p' variant='copy' my='sm'>{lastCompleted.title}</Text> :
                        <Text as='p' variant='copy' my='sm'>No info to display</Text>

                }
            </Box>
            {
                upcoming ?
                    <Box mx= 'md'>
                        <Flex alignItems='center' width='100%' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                            <Box width='23px' mr='sm'>
                                <ArrowRight />
                            </Box>

                            <Text variant='largeCopy' as='p' my='sm'>
                                Up Next
                            </Text>
                        </Flex>
                        <Text as='p' variant='copy' mt='sm'> {upcoming.title}</Text>
                    </Box> :
                    null
            }
        </Box>
    )
}
function mapStateToProps(state) {
    return {
        vid: state.Vehicle.vid,
    };
}

export default connect(mapStateToProps)(TimelineCard);
