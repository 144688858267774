import React, { useEffect, useState } from 'react';
import { onSnapshot, collection, query, orderBy, limit, where } from 'firebase/firestore'
import { connect } from 'react-redux'
import { db } from '../../system/firebase/index'
import { Box, Text, Flex } from 'rebass/styled-components'
import { Link } from 'gatsby'
import Restorer from './subcomponents/Restorer'
import Icon from '../../assets/icons/restorers.svg'


const RestorersCard = ({ vid }) => {
    const [invoices, setInvoices] = useState([])
    const [restorers, setRestorers] = useState([])
    const [current, setCurrent] = useState([])

    useEffect(() => {
        const q = collection(db, 'restorers')
        onSnapshot(q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                output.push(doc.data())
            })
            setRestorers(output)
        })
    }, [])

    useEffect(() => {
        const q = query(collection(db, 'invoices'), where('vid', '==', vid ))
        onSnapshot(q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                output.push(doc.data())
            })
            setInvoices(output)
        })
    }, [vid])
    useEffect(() => {
        if (invoices) {
            const lastItem = invoices[invoices.length - 1]
            setCurrent([lastItem])
        }
    }, [invoices])

    return (
        <Box>
            <Flex alignItems="center" sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Box ml='md' width='25px'>
                    <Icon />
                </Box>
                <Text mx='xs' py='xs' variant='h4' as='h4'>
                    CURRENTLY WITH
                </Text>
                <Box ml='auto' mr='sm'>
                    <Link to='/clients/allRestorer' >
                        <Text variant='body' color='aquaMedium'>
                            View all
                        </Text>
                    </Link>
                </Box>
            </Flex>
            <Box mx= 'md' py= 'sm' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }} >
                {
                    current && current.length ?
                        current.map((invoice, index) => {
                            if (invoice) {
                                const restorer = restorers.filter((restorer) => restorer.id === invoice.restorer)
                                return <Restorer key={index} data={invoice} restorer={restorer[0]} />
                            }
                        }) :
                        <Text ml='md' mt='sm' as='p' variant='copy'>No info to display</Text>
                }
            </Box>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        vid: state.Vehicle.vid,
    };
}

export default connect(mapStateToProps)(RestorersCard);
