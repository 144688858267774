import React from 'react';
import { Box, Text, Flex, Image } from 'rebass/styled-components'
import Moment from 'react-moment'


const Restorer = ({ data, restorer }) => {
    const logo = (restorer !== undefined && restorer.media.logo !== undefined) ? <Image src={process.env.GATSBY_CLOUDINARY_URL + restorer.media.logo.filepath + restorer.media.logo.filename} height='38px' width='103px' sx={{ objectFit: 'cover', objectPosition: 'center' }}/> : <Flex justifyContent='center' alignItems='center' height='38px' width='103px' sx={{ borderRadius: '5px' }} bg='charcoal' ><Text as='p' variant='body' color='aquaMedium'> No Logo</Text></Flex>
    return (
        <Flex justifyContent="space-between">
            {
                data && restorer ?
                    <Box>
                        <Text variant='body' as='p' sx={{ textTransform: 'capitalize' }}>
                            {data.category || null}
                        </Text>
                        <Text variant='body' fontSize='xl' fontWeight='semibold' as='p' mr='sm'>
                            {restorer.name}
                        </Text>
                        <Text variant='body' as='p'>
                            <Moment unix format='DD/MM/YY'>
                                {data.issue_date || null}
                            </Moment>
                        </Text>
                    </Box> : null}
            <Box >
                <Flex
                    alignItems= 'center'
                    justifyContent= 'center'
                    maxWidth='144px'
                    ml='sm'>
                    {logo}
                </Flex>
            </Box>
        </Flex>
    )
}

export default Restorer
