import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import styled from 'styled-components'
import DetailsCard from '../components/dashboard/DetailsCard'
import TimelineCard from '../components/dashboard/TimelineCard'
import RestorersCard from '../components/dashboard/RestorersCard'
import InvoicesCard from '../components/dashboard/InvoicesCard'
import QuotesCard from '../components/dashboard/QuotesCard'
import { connect } from 'react-redux'


const Grid = styled(Box)`
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
        "vehicle-status timeline-status restorer-status"
        "vehicle-status invoice-status quote-status";

        @media only screen and (max-width:768px) { 
            display: block
}
`

const Dashboard = ({ bigText }) => {
    const isSSR = typeof window === 'undefined'
    return (
        <>
            {!isSSR && (
                <Box>
                    <Box variant='container'>
                        <Grid>
                            <Box
                                ml={['sm', 'md']}
                                sx={{ gridArea: 'vehicle-status',
                                    borderLeft: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)'],
                                    borderRight: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)']}}>
                                <DetailsCard />
                            </Box>
                            <Box mt={['xl', 'xl', 'xl', 'xl', '0']} sx={{ gridArea: 'timeline-status', borderRight: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)']}}>
                                <TimelineCard />
                            </Box>
                            <Box mt={['xl', 'xl', 'xl', 'xl', '0']} mr={['0', '0', '0', 'md']} sx={{ gridArea: 'restorer-status', borderRight: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)']}}>
                                <RestorersCard />
                            </Box>
                            <Box mt={['xl', 'xl', 'xl', 'xl', '0']} height={['auto', 'auto', 'auto', '400px']} sx={{ gridArea: 'invoice-status', borderRight: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)']}}>
                                <InvoicesCard />
                            </Box>
                            <Box mt={['xl', 'xl', 'xl', 'xl', '0']} height={['300px', '300px', '300px', '400px']} mr={['0', '0', '0', 'md']} sx={{ gridArea: 'quote-status', borderRight: ['0px', '0px', '0px', '1px solid rgba(255,255,255,0.1)']}}>
                                <QuotesCard />
                            </Box>
                        </Grid>
                    </Box>
                    <Box mt='sm' sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', right: '6px', bottom: ['-20px', '-20px', '-20px', '-20px', '-20px', '-60px']}}>
                            <Text variant='largeBg' as='h2'>{bigText}</Text>
                        </Box>
                    </Box>
                </Box>

            )}
        </>
    )
}


function mapStateToProps(state) {
    return {
        bigText: state.Vehicle.bigText,
    };
}

export default connect(mapStateToProps)(Dashboard);
