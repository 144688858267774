import React from 'react';
import { Box, Text, Flex, Image } from 'rebass/styled-components'
import { connect } from 'react-redux'
import Icon from '../../assets/icons/wheelbase.svg'


const DetailsCard = ({ vehicle }) => {
    const baseUrl = process.env.GATSBY_CLOUDINARY_URL
    const filepath = vehicle.media.images && vehicle.media.images.length ? vehicle.media.images[0].filepath.replace(/\s/g, '%20').replace(/[(]/g, '%28').replace(/[)]/g, '%29') : ''
    const filename = vehicle.media.images && vehicle.media.images.length ? vehicle.media.images[0].filename.replace(/\s/g, '%20').replace(/[(]/g, '%28').replace(/[)]/g, '%29') : null
    const imageUrl = baseUrl + filepath + filename
    const image = <Image src={imageUrl} height='100%' sx={{ objectFit: 'cover', objectPosition: 'center' }}/>
    const noImage = <Flex bg='greyLight' width={['326px', '326px', '417px']} height='281px' alignItems='center' justifyContent='center'>
        <Text as='h3' variant='h3'>No Image</Text>
    </Flex>
    const imageOutput = vehicle.media.images ? image : noImage


    return (
        <Box>
            <Box sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Flex alignItems="center">
                    <Box ml={['0', '0', '0', '0', 'md']} width='30px'>
                        <Icon />
                    </Box>
                    <Text mx='sm' py='xs' variant='h4' as='h4'>
                        VEHICLE DETAILS
                    </Text>
                </Flex>
            </Box>
            <Box ml={['0', '0', '0', '0', 'md']}>
                <Flex
                    alignItems= 'center'
                    justifyContent= 'center'
                    maxWidth= '400px'
                    mt={['sm', 'sm', 'sm', 'sm', 'md']}
                    mr='md'>
                    {imageOutput}
                </Flex>
                <Box>
                    <Flex flexDirection='column' >
                        <Flex py='sm' sx={{ borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            <Text as='p' variant='carDetailsCopy'>Chassis no.</Text>
                            <Text as='p' variant='carDetailsCopy'>{vehicle.characteristics.chassisNo}</Text>
                        </Flex>
                        <Flex py='sm' sx={{ borderBottom: '1px solid rgba(255,255,255,0.05)' }}>
                            <Text as='p' variant='carDetailsCopy'>Engine no.</Text>
                            <Text as='p' variant='carDetailsCopy'>{vehicle.characteristics.engineNo}</Text>
                        </Flex>
                        <Box my='sm'>
                            <Text as='p' variant='carDetailsCopy'>{vehicle.shortDescription}</Text>
                        </Box>
                    </Flex>
                    {/* <Button variant='primary_grey' mt={['xs', 'xs', 'xs', 'xs', 'xs', 'md']} mb={['0px', '0px', '0px', '0px', '0px', 'md']} width={['312px', '312px', '312px', '312px', '192px']}>
                        Download snapshot
                    </Button> */}
                </Box>
            </Box>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
    };
}

export default connect(mapStateToProps)(DetailsCard);
